import request from '@/utils/request'
//根据角色编号查询权限(总台)
export function getRoleAuth(query) {
  return request({
    url: '/orgAuthGinseng/getRoleAuth',
    method: 'get',
    params: query
  })
}
//角色一览查询(合作伙伴)
export function listPartnerPage(query) {
  return request({
    url: '/orgRoleGinseng/listPartnerPage',
    method: 'get',
    params: query
  })
}
//角色新增(合作伙伴)
export function saveGinseng(data) {
  return request({
    url: '/orgRoleGinseng/saveGinseng',
    method: 'post',
    data
  })
}
//角色修改(合作伙伴)
export function updateGinseng(data) {
  return request({
    url: '/orgRoleGinseng/updateGinseng',
    method: 'post',
    data
  })
}
//角色删除
export function deleteById(query) {
  return request({
    url: '/orgRoleGinseng/deleteById',
    method: 'DELETE',
    params: query
  })
}
//角色详情
export function adminRoleDetail(query) {
  return request({
    url: '/orgRoleGinseng/listById',
    method: 'get',
    params: query
  })
}
//添加角色权限
export function addRoleAuth(data) {
  return request({
    url: '/orgRoleAuthGinseng/addRoleAuth',
    method: 'post',
    data
  })
}
//修改角色权限(普通权限)
export function editPartnerRoleAuth(data) {
  return request({
    url: '/orgRoleAuthGinseng/editPartnerRoleAuth',
    method: 'post',
    data
  })
}
//根据合作伙伴编号查询该合作机构最高权限的角色编号
export function roleNotAuthority(query) {
  return request({
    url: '/orgRoleGinseng/roleNotAuthority',
    method: 'get',
    params: query
  })
}
