<template>
  <div class="user">
    <div class="container">
      <el-card shadow="never">
        <div class="operation">
          <el-form :model="queryInfo" label-width="85px" :inline="true">
            <el-form-item style="margin: 0 0 12px 0;">
              <el-button
                v-if="$hasPerms('menu:add')"
                @click="
                  dialogVisible.role = true
                  dialogType = 0
                "
                type="primary"
                >添加角色</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <el-table
          @sort-change="sortChange"
          :data="roleTable"
          :header-cell-style="{ background: '#F5F6F7', color: '#252631' }"
        >
          <el-table-column prop="id" label="ID" align="center" width="110px" />
          <el-table-column prop="roleName" label="角色名" align="center" />
          <el-table-column prop="description" label="角色描述" align="center" width="200" />
          <el-table-column prop="userCount" label="用户数量" align="center" sortable />
          <el-table-column label="操作" width="200" align="center">
            <template v-slot="{ row }">
              <el-button type="text" v-if="$hasPerms('menu:authority')" @click="permissionItem(row)"
                >权限管理</el-button
              >
              <el-button type="text" v-if="$hasPerms('menu:edit')" @click="editItem(row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                v-if="$hasPerms('menu:delete')"
                @click="deleteItem(row)"
                class="delete_btn"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-dialog
          class="role_dialog"
          :title="dialogType ? '编辑角色' : '添加角色'"
          :visible.sync="dialogVisible.role"
          width="30%"
          @close="handleClose"
          center
        >
          <el-form ref="roleObj" :model="roleObj" :rules="roleRules" class="common_from">
            <el-form-item label="角色名：" prop="roleName" label-width="110px">
              <el-input v-model="roleObj.roleName" placeholder="请输入角色名" />
            </el-form-item>
            <el-form-item label="角色描述" prop="description" label-width="110px">
              <el-input
                v-model="roleObj.description"
                placeholder="请输入角色描述"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
              />
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="dialogVisible.role = false" class="cancel_btn">取 消</el-button>
          </div>
        </el-dialog>

        <el-dialog title="分配权限" :visible.sync="rloedialog" width="50%" class="account_dialog">
          <myTree
            v-if="rloedialog"
            :data="menuList"
            :props="defaultProps"
            @check="currentChecked"
            show-checkbox
            expand-on-click-node
            node-key="id"
            :default-checked-keys="currentNode"
            :default-expanded-keys="roleObj.authIdList"
          ></myTree>

          <div style="display: flex; justify-content: center; margin-top: 40px">
            <div v-if="isrole">
              <el-button
                v-if="rolemenuList.length == 0"
                @click="submitFormAuth"
                type="primary"
                plain
                >确 定</el-button
              >
              <el-button
                v-else-if="roleObj.roleId != roleId"
                @click="editPartnerRoleAuth"
                type="primary"
                >确 定</el-button
              >
            </div>

            <div style="margin-left: 10px">
              <el-button @click="rloedialog = false">取 消</el-button>
            </div>
          </div>
        </el-dialog>
      </el-card>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  listPartnerPage,
  saveGinseng,
  updateGinseng,
  deleteById,
  getRoleAuth,
  addRoleAuth,
  editPartnerRoleAuth,
  roleNotAuthority,
  getRoleById,
  adminRoleDetail,
  detail
} from '@/api/authority'
import { Loading } from 'element-ui'
// import { getMenu } from '@/api/menu'
import { pagination } from '@/mixins/pagination.js'
import myTree from './components/tree'
const defaultRoleObj = Object.freeze({
  notAuthority: 1, //是否为最高权限 1->不为最高权限;2->最高权限
  partnerId: JSON.parse(sessionStorage.getItem('typeMode'))?.id,
  roleChannel: 2,
  roleName: '', // 角色名
  description: '', // 备注
  systemOrReview: '1', //角色类别
  authIdList: [],
  userName: '',
  createId: '',
  createTime: '',
  id: '',
  currentKey: [], // 选中key
  currentNode: [] // 选中node
})
export default {
  name: 'Role',
  components: {
    myTree
  },
  mixins: [pagination],

  data() {
    return {
      rloedialog: false,
      roleNotAuthorityId: '', //最高权限的角色编号
      halfCheckedKeys: [], //收集父id
      menuList: [], //所有权限一览
      rolemenuList: [], //已经分配的角色权限
      roleId: '', //该合作机构最高权限的角色编号
      isrole: false, //判断是否修改了权限菜单
      roleTable: [],
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        partnerId: JSON.parse(sessionStorage.getItem('typeMode'))?.id,
        orders: 1
      },
      dialogType: 0, // 新增
      dialogVisible: {
        role: false, // 角色弹窗
        auth: false // 权限弹窗
      },
      offsetTopNumber: 65,
      roleObj: { ...defaultRoleObj },
      authDescription: '', // 权限描述
      whetherClick: false,
      defaultProps: {
        children: 'children',
        label: 'authName'
      },
      selected: {
        node: [],
        expanded: []
      },
      roleRules: {
        roleName: [{ required: true, message: '请输入角色', trigger: 'blur' }],
        description: [{ required: true, message: '请输入角色描述', trigger: 'blur' }]
      },
      options: [
        {
          value: '1',
          label: '系统角色'
        },
        {
          value: '2',
          label: '评奖角色'
        },
        {
          value: '4',
          label: '编审角色'
        }
      ]
    }
  },

  created() {
    this.search()
    this.roleNotAuthority()
  },

  methods: {
    // 查询角色带分页
    async search() {
      const { data: res } = await listPartnerPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.roleTable = res.data.list

        this.total = res.data.total
      }
    },
    sortChange(column, prop, order) {
      if (column.prop == 'userCount' && column.order == 'ascending') {
        this.queryInfo.orders = 2
        this.search()
        return
      } else if (column.prop == 'userCount' && column.order == 'descending') {
        this.queryInfo.orders = 3
        this.search()
        return
      } else {
        this.queryInfo.orders = 1
      }
    },
    // 根据合作伙伴编号查询该合作机构最高权限的角色编号
    async roleNotAuthority() {
      const { data: res } = await roleNotAuthority({
        partnerId: JSON.parse(sessionStorage.getItem('typeMode'))?.id
      })
      if (res.resultCode === 200) {
        this.roleNotAuthorityId = res.data
      }
    },
    //查询最高权限菜单
    async getroleId() {
      const res = await getRoleAuth({ roleId: this.roleNotAuthorityId })
      if (res.data.resultCode == 200) {
        this.menuList = res.data.data
      }
    },

    //回显
    async searchRoleById(roleId) {
      this.roleObj.authIdList = []
      this.currentNode = []
      const { data: res } = await getRoleAuth({ roleId })

      if (res.resultCode === 200) {
        this.rolemenuList = res.data
        this.recursionId(res.data)
      } else {
        this.$message.warning(res.message)
      }
    },
    // 机构获取权限展示key
    recursionId(arr) {
      arr.forEach((item) => {
        if (item.children != null) {
          if (item.children.length == 0) {
            this.currentNode.push(item.id)
            this.roleObj.authIdList.push(item.id)
          } else {
            this.recursionId(item.children)
          }
        } else {
          this.currentNode.push(item.id)
          this.roleObj.authIdList.push(item.id)
        }
      })
    },

    setTree(datas) {
      let arry = []
      datas.forEach((item) => {
        item.children.forEach((item) => {
          arry.push(item.id)
          if (item.children.length) {
            this.setTree(item.children)
          }
        })
      })
      this.roleObj.currentNode = arry
    },

    // 编辑
    editItem(row) {
      adminRoleDetail({ id: row.id }).then(({ data: res }) => {
        const { roleName, description } = res.data
        this.roleObj.description = description
        this.roleObj.roleName = roleName
        // console.log()
        this.dialogType = 1
        this.dialogVisible.role = true
      })
      // this.searchRoleById(row.id)
    },
    // 删除
    deleteItem(row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          const { data: res } = await deleteById({ roleId: row.id })
          if (res.resultCode === 200) {
            this.$message.success('删除成功')
            this.search()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {})
    },
    // 弹窗关闭
    handleClose() {
      this.$refs.roleObj.resetFields()
      Object.assign(this.roleObj, defaultRoleObj)
      this.dialogVisible.role = false
    },
    // 提交
    submitForm() {
      this.$refs.roleObj.validate(async (valid) => {
        if (valid) {
          let res
          if (this.dialogType) {
            res = await updateGinseng(this.roleObj)
          } else {
            res = await saveGinseng(this.roleObj)
          }

          if (res.data.resultCode === 200) {
            this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)

            this.search()
          } else {
            this.$message.error(res.message)
          }

          this.dialogVisible.role = false
        }
      })
    },
    // 分配权限
    async permissionItem(row) {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: ''
      })
      this.isrole = false
      this.getroleId()
      this.searchRoleById(row.id).then(() => {
        this.roleObj.roleId = row.id
        this.rloedialog = true
        loadingInstance.close()
      })
    },
    //第一次分配权限
    async submitFormAuth() {
      this.roleObj.authIdList = this.roleObj.authIdList.concat(this.halfCheckedKeys)

      const { data: res } = await addRoleAuth(this.roleObj)
      if (res.resultCode === 200) {
        this.$message.success('权限新增成功')
        this.rloedialog = false
      } else {
        this.$message.error(res.message)
        this.rloedialog = false
      }

      this.search()
    },

    //普通角色进行更新修改角色
    async editPartnerRoleAuth() {
      this.roleObj.authIdList = this.roleObj.authIdList.concat(this.halfCheckedKeys)

      const { data: res } = await editPartnerRoleAuth(this.roleObj)
      if (res.resultCode === 200) {
        this.$message.success('权限更新成功')
        this.rloedialog = false
      } else {
        this.$message.error(res.message)
        this.rloedialog = false
      }

      this.search()
    },
    //选择菜单
    currentChecked(nodeObj, SelectedObj) {
      this.roleObj.authIdList = SelectedObj.checkedKeys
      this.halfCheckedKeys = SelectedObj.halfCheckedKeys
      this.isrole = true
    },
    handleCloseAuth() {
      Object.assign(this.roleObj, defaultRoleObj)
      this.selected.node = []
      this.selected.expanded = []
      this.rloedialog = false
      this.whetherClick = false
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  overflow: auto;
  .container {
    height: 80vh;
    overflow: auto;
  }
  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
.role_dialog {
  /deep/ .el-dialog__header {
    text-align: left;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
  }
  /deep/ .el-dialog__title {
    font-size: 16px !important;
    line-height: 22px !important;
    color: #252631 !important;
  }
}
.cancel_btn {
  border: 1px solid #4e93fb;
  color: #4e93fb;
}
.delete_btn {
  color: #fd5469;
}
</style> 
